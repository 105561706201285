import React, { Component } from 'react';
import styled from 'styled-components';

import FormStyle from '../../styles/formStyle';

const StyledForm = styled(FormStyle)`
	color: ${(props) => props.theme.colors.secondary};

	.control {
		@media (min-width: 769px) {
			margin-right: 4rem;
		}
	}

	input,
	textarea,
	select {
		border-bottom-color: ${(props) => props.theme.colors.secondary};
		color: ${(props) => props.theme.colors.secondary};
	}
/*
	option {
		background-color: ${(p) => p.theme.colors.secondary};
	}
*/
	::placeholder {
		color: ${(props) => props.theme.colors.textGrey};
	}

	[type='submit'] {
		color: ${(props) => props.theme.colors.primary};
		border-color: ${(props) => props.theme.colors.primary};

		&:hover {
			color: ${(props) => props.theme.colors.lightest};
			background-color: ${(props) => props.theme.colors.primary};
		}

		&::before {
			background-color: ${(props) => props.theme.colors.primary};
		}

		&::after {
			border-right-color: ${(props) => props.theme.colors.primary};
			border-bottom-color: ${(props) => props.theme.colors.primary};
		}
	}

	.submit-row {
		.column {
			@media (min-width: 769px) {
				display: flex;
				flex-direction: column;
				justify-content: flex-end;

				&:nth-child(1) {
					padding-bottom: 1.2rem;
				}

				&:nth-child(3) {
					padding-bottom: 5rem;

					button {
						margin-right: 7rem;
					}
				}
			}
		}
	}
`;

const StyledConfirmation = styled.div`
	p {
		color: ${({ theme }) => theme.colors.textDarkerGrey};
	}
`;

function encode(data) {
	return Object.keys(data)
		.map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
		.join('&');
}

export default class ContactForm extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isValidated: false,
			isSubmitted: false,
			referralNotesOpen: false,
			otherNotesOpen: false,
		};
	}

	handleChange = (e) => {
		this.setState({ [e.target.name]: e.target.value });

		if (e.target.name === 'referral') {
			if (e.target.value === 'Referral') {
				this.setState({ referralNotesOpen: true });
			} else {
				this.setState({ referralNotesOpen: false });
			}
			if (e.target.value === 'Other') {
				this.setState({ otherNotesOpen: true });
			} else {
				this.setState({ otherNotesOpen: false });
			}
		}
	};

	handleSubmit = (e) => {
		e.preventDefault();
		const form = e.target;

		if (typeof window !== 'undefined') {
			window.dataLayer.push({
				event: "conversion(ContactForm)",
				gtm: { uniqueEventId: 5, start: 1719233636355 },
				eventModel: {
					email: this.state.email || ''
				}
			})
		}

		fetch('/', {
			method: 'POST',
			headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
			body: encode({
				'form-name': form.getAttribute('name'),
				...this.state,
			}),
		})
			.then(this.setState({ isSubmitted: true }))
			.catch((error) => alert(error));
	};

	render() {
		if (!!this.state.isSubmitted) {
			return (
				<StyledConfirmation>
					<div className="columns is-centered">
						<div className="column is-narrow has-text-white">
							<h2 className="has-text-centered">Thanks!</h2>
							<p className="has-text-centered">
								We have received your submission.
							</p>
						</div>
					</div>
				</StyledConfirmation>
			);
		} else {
			return (
				<StyledForm
					name="Contact Page"
					method="post"
					action="/contact/thanks/"
					data-netlify="true"
					data-netlify-honeypot="bot-field"
					onSubmit={this.handleSubmit}
					id="contact-page-form"
				>
					{/* The `form-name` hidden field is required to support form submissions without JavaScript */}
					<input type="hidden" name="form-name" value="Contact Page" />
					<div hidden>
						<label>
							Don’t fill this out:{' '}
							<input name="bot-field" onChange={this.handleChange} />
						</label>
					</div>
					<div className="columns">
						<div className="column">
							<div className="control">
								<input
									type={'text'}
									name={'first-name'}
									onChange={this.handleChange}
									id={'first-name'}
									required={true}
									placeholder={'Jane'}
								/>
							</div>
							<label htmlFor={'first-name'}>First Name</label>
						</div>
						<div className="column">
							<div className="control">
								<input
									type={'text'}
									name={'last-name'}
									onChange={this.handleChange}
									id={'last-name'}
									required={true}
									placeholder={'Smith'}
								/>
							</div>
							<label htmlFor={'last-name'}>Last Name</label>
						</div>
						<div className="column">
							<div className="control">
								<input
									type={'text'}
									name={'phone'}
									onChange={this.handleChange}
									id={'phone'}
									placeholder={'(705) 555-5555'}
								/>
							</div>
							<label htmlFor={'phone'}>Phone</label>
						</div>
					</div>
					<div className="columns">
						<div className="column">
							<div className="control">
								<input
									type={'email'}
									name={'email'}
									onChange={this.handleChange}
									id={'email'}
									required={true}
									placeholder={'janesmith@email.com'}
								/>
							</div>
							<label htmlFor={'email'}>Email</label>
						</div>
						<div className="column">
							<div className="control">
								<select
									name={'referral'}
									id={'referral'}
									onChange={this.handleChange}
									defaultValue={''}
									required={true}
								>
									<option value="" disabled>
										— —
									</option>
									<option value="Lawn Sign / For Sale Sign">
										Lawn Sign / For Sale Sign
									</option>
									<option value="Facebook / Instagram">
										Facebook / Instagram
									</option>
									<option value="Google Ad">Google Ad</option>
									<option value="Radio">Radio</option>
									<option value="Billboard">Billboard</option>
									<option value="Bus Stop">Bus Stop</option>
									<option value="Referral">Referral</option>
									<option value="Other">Other</option>
								</select>
								<label htmlFor={'referral'}>How did you hear about us?</label>
							</div>
						</div>
						{this.state.referralNotesOpen && (
							<div className="column">
								<div className="control">
									<textarea
										name={'referral-notes'}
										onChange={this.handleChange}
										id={'referral-notes'}
										required={true}
										rows="1"
										placeholder={'Tell Us More'}
									/>
								</div>
								<label htmlFor={'referral-notes'}>Referral Notes</label>
							</div>
						)}
						{this.state.otherNotesOpen && (
							<div className="column">
								<div className="control">
									<textarea
										name={'other-notes'}
										onChange={this.handleChange}
										id={'other-notes'}
										required={true}
										rows="1"
										placeholder={'Tell Us More'}
									/>
								</div>
								<label htmlFor={'other-notes'}>Other Notes</label>
							</div>
						)}
					</div>
					<div className="columns submit-row">
						<div className="column is-6">
							<div className="control">
								<textarea
									name={'message'}
									onChange={this.handleChange}
									id={'message'}
									required={true}
									rows="3"
									placeholder={'Your Message'}
								/>
							</div>
							<label htmlFor={'message'}>Message</label>
						</div>
						<div className="column is-narrow">
							<button className="button button-1" type="submit">
								Submit
							</button>
						</div>
					</div>
				</StyledForm>
			);
		}
	}
}
