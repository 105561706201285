import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';

import PreviewCompatibleImage from '../PreviewCompatibleImage';

import locationIcon from '../../img/location_icon.svg';
import bedIcon from '../../img/bed_listingdetail.svg';
import bathIcon from '../../img/bath_listingdetail.svg';

const StyledListing = styled.div`
	box-shadow: 0px 7px 23px -17px ${(props) => props.theme.colors.textGrey};
	position: relative;
	a {
		color: currentColor;

		/* .gatsby-image-wrapper {
			transition: 300ms;
		}

		&:hover {
			figure {
				.gatsby-image-wrapper {
					transform: scale(1.125);
				}
			}
		} */
	}

	figure {
		img {
			width: 100%;
			height: 380px;
			object-fit: cover;
		}

		position: relative;
		/* overflow: hidden; */

		figcaption {
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: flex-start;
			position: absolute;
			bottom: -1px;
			left: 0;
			background-color: ${(props) => props.theme.colors.lightest};
			border-top-left-radius: 9px;
			border-top-right-radius: 9px;
			padding: 1rem 1.5rem;

			@media (max-width: 768px) {
				padding: 0.75rem 1rem;
			}

			img {
				width: 2rem;
				height: auto;

				@media (max-width: 768px) {
					width: 1rem;
				}
			}

			h2 {
				font-weight: ${(props) => props.theme.fonts.weights.regular};
				font-family: ${(props) => props.theme.fonts.secondary};
				color: ${(props) => props.theme.colors.secondary};
				letter-spacing: 0.08em;
				text-transform: none;
				font-size: 2rem;
				line-height: 1;
				padding-left: 1em;

				@media (max-width: 768px) {
					font-size: 1.3rem;
				}

				span {
					font-family: ${(props) => props.theme.fonts.primary};
					color: ${(props) => props.theme.colors.tertiary};
					letter-spacing: 0.02em;
					text-transform: uppercase;
					font-size: 0.8em;
					display: inline-block;
					margin-top: 0.5em;
				}
			}
		}
	}

	small {
		font-size: 9px;
		position: absolute;
		bottom: 12px;
		left: 24px;
	}

	.main-details {
		padding: 2rem 2.5rem 4rem 2.5rem;

		@media (max-width: 768px) {
			padding: 1.5rem 1rem;
		}

		> .column {
			&:nth-child(1) {
				border-right: 1px solid ${(props) => props.theme.colors.quaternary};
			}

			&:nth-child(2) {
				padding-left: 3rem;

				@media (max-width: 768px) {
					padding-left: 1.5rem;
				}

				p {
					@media (max-width: 768px) {
						font-size: 1.2rem;
					}
				}
			}
		}

		ul {
			display: flex;
			align-items: center;
			justify-content: flex-start;

			li {
				display: flex;
				align-items: center;
				justify-content: flex-start;
				font-weight: ${(props) => props.theme.fonts.weights.bold};

				@media (max-width: 768px) {
					font-size: 1.2rem;
				}

				+ li {
					margin-left: 1.5rem;

					@media (max-width: 768px) {
						margin-left: 1rem;
					}
				}

				img {
					width: 2.5rem;
					height: auto;
					margin-right: 1rem;

					@media (max-width: 768px) {
						margin-right: 0.5rem;
						width: 1.8rem;
					}
				}
			}
		}

		h3 {
			text-transform: uppercase;

			span {
				font-size: 1.6em;
				font-weight: ${(props) => props.theme.fonts.weights.bold};
				color: ${(props) => props.theme.colors.primary};

				@media (max-width: 599px) {
					font-size: 1.2rem;
				}
			}

			@media (max-width: 768px) {
				font-size: 1.2rem;
			}
		}

		.details-text {
			position: relative;
			height: 100%;

			p {
				font-size: 1.6rem;
			}
			.deets {
				width: 100%;
				height: 124px;
				display: -webkit-box;
				-webkit-box-orient: vertical;
				-webkit-line-clamp: 5;
				overflow: hidden;
			}
			.button {
				position: absolute;
				right: 0;
				bottom: -5.5rem;

				@media (max-width: 768px) {
					bottom: -4rem;
					font-size: 1.4rem;
				}
			}
		}
	}
`;

const formatMoney = (input) => {
	const formatter = new Intl.NumberFormat('en-US', {
		style: 'currency',
		currency: 'USD',
		maximumFractionDigits: 0,
	});

	return formatter.format(input);
};

const getSlug = (listing) => {
	return `${listing?.address?.streetNumber
		? listing.address.streetNumber.toLowerCase()
		: ''
		}-${listing?.address?.streetName ? listing.address.streetName.toLowerCase() : ''
		}-${listing?.address?.streetSuffix
			? listing.address.streetSuffix.toLowerCase()
			: ''
		}-${listing?.address?.city ? listing.address.city.toLowerCase() : ''}-${listing.mlsNumber
		}`.replace(' ', '-');
};

const ListingDetail = ({ listing }) => {
	return (
		<StyledListing>
			<Link to={`/listings/${getSlug(listing)}`}>
				<figure>
					<img src={`https://cdn.repliers.io/${listing.images[0]}`} />
					<figcaption>
						<img src={locationIcon} alt="" />
						<h2>
							{listing.address.unitNumber ? `${listing.address.unitNumber}-` : ''}{listing.address.streetNumber} {listing.address.streetName}{' '}
							{listing.address.streetSuffix} <br />
							<span>
								{listing.address.city}, {listing.address.state}
							</span>
						</h2>
					</figcaption>
				</figure>
				<div className="columns is-mobile main-details">
					<div className="column is-4">
						<div className="columns">
							<div className="column">
								<h3>
									For Sale <br />
									<span>{formatMoney(listing.listPrice)}</span>
								</h3>
							</div>
						</div>
						<div className="columns">
							<div className="column">
								<ul>
									<li>
										<img src={bedIcon} alt="" />
										{listing?.details?.numBedrooms
											? listing.details.numBedrooms
											: 0}
										+
										{listing?.details?.numBedroomsPlus
											? listing.details.numBedroomsPlus
											: 0}
									</li>
									<li>
										<img src={bathIcon} alt="" />
										{listing?.details?.numBathrooms
											? listing.details.numBathrooms
											: 0}
										+
										{listing?.details?.numBathroomsPlus
											? listing.details.numBathroomsPlus
											: 0}
									</li>
								</ul>
							</div>
						</div>
					</div>
					<div className="column is-8">
						<div className="details-text">
							<p className="deets">{listing.details.description}</p>
							<p className="button button-3">View Details</p>
						</div>
					</div>
				</div>
				<small>{listing.office.brokerageName}</small>
			</Link>
		</StyledListing>
	);
};

export default ListingDetail;
