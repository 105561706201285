import React, { Component } from 'react';
import styled from 'styled-components';

import FormStyle from '../../styles/formStyle';

const StyledForm = styled(FormStyle)`
	color: ${(props) => props.theme.colors.secondary};
	width: 50rem;
	max-width: 100%;

	h2 {
		font-size: 3rem;
		text-transform: none;
	}

	[class*='AccentLine'] {
		margin-top: 2rem;
	}

	input,
	textarea {
		border-bottom-color: ${(props) => props.theme.colors.secondary};
		color: ${(props) => props.theme.colors.secondary};
	}

	::placeholder {
		color: ${(props) => props.theme.colors.textDarkerGrey};
	}

	[type='submit'] {
		border-color: ${(props) => props.theme.colors.primary};

		&::before {
			display: none;
		}

		&::after {
			display: none;
		}
	}
`;

function encode(data) {
	return Object.keys(data)
		.map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
		.join('&');
}

export default class NewsletterForm extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isValidated: false,
			isSubmitted: false,
		};
	}

	handleChange = (e) => {
		this.setState({ [e.target.name]: e.target.value });
	};

	handleSubmit = (e) => {
		e.preventDefault();
		const form = e.target;

		if (typeof window !== 'undefined') {
			window.dataLayer.push({
				event: "conversion(NewsletterForm)",
				gtm: { uniqueEventId: 5, start: 1719233636355 },
				eventModel: {
					email: this.state.email || ''
				}
			})
		}

		fetch('/', {
			method: 'POST',
			headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
			body: encode({
				'form-name': form.getAttribute('name'),
				...this.state,
			}),
		})
			.then(this.setState({ isSubmitted: true }))
			.catch((error) => alert(error));
	};

	render() {
		if (!!this.state.isSubmitted) {
			return (
				<div className="columns is-centered">
					<div className="column is-narrow has-text-white">
						<h2 className="has-text-centered">Thanks!</h2>
						<p className="has-text-centered">
							We have received your submission.
						</p>
					</div>
				</div>
			);
		} else {
			return (
				<StyledForm
					name="Newsletter Form"
					method="post"
					action="/contact/thanks/"
					data-netlify="true"
					data-netlify-honeypot="bot-field"
					onSubmit={this.handleSubmit}
				>
					{/* The `form-name` hidden field is required to support form submissions without JavaScript */}
					<input type="hidden" name="form-name" value="Newsletter Form" />
					<div hidden>
						<label>
							Don’t fill this out:{' '}
							<input name="bot-field" onChange={this.handleChange} />
						</label>
					</div>
					<div className="columns">
						<div className="column">
							<div className="control">
								<input
									type={'text'}
									name={'first-name'}
									onChange={this.handleChange}
									id={'first-name'}
									required={true}
									placeholder={'First Name*'}
								/>
							</div>
							<label className="visually-hidden" htmlFor={'first-name'}>
								First Name*
							</label>
						</div>
					</div>
					<div className="columns">
						<div className="column">
							<div className="control">
								<input
									type={'text'}
									name={'last-name'}
									onChange={this.handleChange}
									id={'last-name'}
									required={true}
									placeholder={'Last Name*'}
								/>
							</div>
							<label className="visually-hidden" htmlFor={'last-name'}>
								Last Name*
							</label>
						</div>
					</div>
					<div className="columns">
						<div className="column">
							<div className="control">
								<input
									type={'email'}
									name={'email'}
									onChange={this.handleChange}
									id={'email'}
									required={true}
									placeholder={'Email*'}
								/>
							</div>
							<label className="visually-hidden" htmlFor={'email'}>
								Email*
							</label>
						</div>
					</div>
					<div className="columns is-centered">
						<div className="column is-narrow">
							<button className="button button-4" type="submit">
								Sign Up!
							</button>
						</div>
					</div>
				</StyledForm>
			);
		}
	}
}
