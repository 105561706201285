import React from 'react';
import { ThemeProvider } from 'styled-components';

import theme from '../styles/theme/theme';
import '../styles/all.scss';
import GlobalStyle from '../styles/GlobalStyle';

import Footer from './Footer';
import ContactFooter from './ContactFooter';
import Header from './Header';
import { AlertBanner } from './alert-banner';

import { useStaticQuery } from 'gatsby';

const TemplateWrapper = ({ children, page }) => {

	const result = useStaticQuery(
		graphql`
			query {
				allMarkdownRemark(filter: {frontmatter: {template_key: {eq: "community"}}}) {
					edges {
						node {
							frontmatter {
								name
							}
						}
					}
				}
			}
		`
	);
	const communities = result.allMarkdownRemark.edges.map((edge) => edge.node.frontmatter.name);

	return (
		<ThemeProvider theme={theme}>
			<>
				<GlobalStyle />
				<AlertBanner page={page} />
				<div style={{ position: 'relative' }}>
					<Header page={page} communities={communities} />
					<main role="main">{children}</main>
					{page === 'contact' || page === 'our-team' ? (
						<ContactFooter />
					) : (
						<Footer page={page} />
					)}
				</div>
			</>
		</ThemeProvider>
	);
};

export default TemplateWrapper;
