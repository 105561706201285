import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';

import SocialIcons from '../components/social/SocialIcons';

import logo from '../img/parker-coulter-logo.png';

const StyledHeader = styled.header`
	background-color: ${(props) => props.theme.colors.secondary};

	${(props) =>
		props.page === 'home'
			? 'position: absolute; top: 0; right: 0; left: 0; background: transparent;'
			: undefined}

	@media (max-width: 1087px) {
		display: flex;
		align-items: center;
		height: 9rem;
		padding: 0 1.9rem;
	}

	@media (min-width: 1088px) {
		height: 15rem;
	}

	.nav-container {
		align-items: stretch;
		display: flex;
		min-height: 3.25rem;
		width: 100%;

		@media (max-width: 1087px) {
			display: block;
		}

		@media (min-width: 1088px) {
			padding: 0 3rem;
		}
	}

	.navbar-brand {
		align-items: center;

		[title='Logo'] {
			img {
				height: 6rem;
				max-height: 6rem;
				width: auto;

				@media (max-width: 499px) {
					height: auto;
					max-height: 7rem;
					width: 50vw;
				}

				@media (min-width: 1088px) and (max-width: 1280px) {
					height: auto;
					max-height: 7rem;
					width: 20vw;
				}
			}
		}

		.navbar-burger {
			background: transparent;
			border: none;
			color: ${(props) => props.theme.colors.lightest};
			padding: 0;
			transition-duration: 500ms;

			span {
				height: 4px;
				width: 24px;
				left: calc(50% - 12px);

				&:nth-child(1) {
					top: calc(50% - 10px);
				}

				&:nth-child(2) {
					top: calc(50% - 2px);
				}

				&:nth-child(3) {
					top: calc(50% + 6px);
				}
			}

			&.is-active {
				position: fixed;
				z-index: 3;
				right: 8.65rem;

				@media (max-width: 768px) {
					right: 1.9rem;
				}

				@media (min-width: 769px) and (max-width: 1087px) {
					right: 13.8rem;
				}

				span:nth-child(1) {
					transform: translateY(7px) rotate(45deg);
				}

				span:nth-child(3) {
					transform: translateY(-9px) rotate(-45deg);
				}

				+ .social-icons {
					margin-left: auto;
				}
			}
		}
	}

	.navbar-menu {
		@media (max-width: 1087px) {
			height: 0;
			overflow: hidden;
			padding: 0;
			opacity: 0;
			transition: opacity 500ms;
			display: flex;

			&.is-active {
				height: auto;
				opacity: 1;
				position: fixed;
				top: 0;
				right: 0;
				bottom: 0;
				left: 0;
				background-color: rgba(39, 39, 39, 0.9);
				flex-direction: column;
				justify-content: center;
			}
		}
		.mobile-nav-close {
			background: transparent;
			border: none;

			@media (min-width: 1088px) {
				display: none;
			}
		}

		.navbar-item {
			a {
				font-family: ${(props) => props.theme.fonts.secondary};
				font-weight: ${(props) => props.theme.fonts.weights.semiBold};
				font-size: 1.6rem;
				color: ${(props) => props.theme.colors.lightest};
				text-transform: uppercase;
				letter-spacing: 2.16px;
				transition: 300ms;
				position: relative;

				&::after {
					content: '';
					height: 2px;
					width: 100%;
					background-color: ${(props) => props.theme.colors.tertiary};
					position: absolute;
					right: 0;
					bottom: -0.75rem;
					left: 0;
					opacity: 0;
					transition: 300ms ease-in-out;
				}

				&.navbar-link {
					padding-right: 8px;
				}
				&.navbar-link::after {
					display: none;
				}

				&:hover {
					color: ${(props) => props.theme.colors.quaternary};

					&::after {
						opacity: 1;
					}
				}

				&[aria-current='page'] {
					&::after {
						opacity: 1;
					}
				}

				@media (min-width: 1088px) and (max-width: 1280px) {
					font-size: calc(2vw - 10px);
				}
			}
			
			.navbar-link {
				color: #fff;
				&:hover {
					color: #fff;
					background-color: transparent;
				}
			}

			.navbar-dropdown {
				li {
					padding: 8px 0;
					a {
						color: #BBB;
						font-size: 14px
					}
				}
			}

			@media (min-width: 1024px) {
			.navbar-dropdown {
					top: 100px;
					background-color: #fff;
					border-radius: 6px;
					border-top: none;
					li {
						a {
							color: #272727;
						}
						&:last-child {
							margin-bottom: 0;
						}
					}
				}
			}
		}

		.navbar-end {
			margin-right: 2rem;

			@media (max-width: 1087px) {
				margin-right: 0;
			}

			.navbar-item {
				padding: 0.5rem 2rem;
			}
		}
	}

	.social-icons {
		display: flex;
		flex-direction: column;
		justify-content: center;
		position: relative;

		&::before {
			content: '';
			display: inline-block;
			width: 1px;
			height: 4rem;
			background: white;
			position: absolute;
			left: 0;
			top: 50%;
			transform: translateY(-50%);
		}

		&.mobile {
			margin-top: 1.8rem;

			@media (min-width: 769px) {
				display: none;
			}

			&::before {
				display: none;
			}

			> ul > li + li {
				margin-left: 2.5rem;
			}
		}

		&.tablet {
			margin-left: 1.25rem;
			padding-left: 1.5rem;
			display: none;

			@media (min-width: 769px) and (max-width: 1087px) {
				display: block;
			}
		}

		&.desktop {
			padding-left: 3rem;
			@media (max-width: 1087px) {
				display: none;
			}
		}
	}
`;

class Header extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			active: false,
			navBarActiveClass: '',
			commHamburgerOpen: false,
		};
	}

	toggleHamburger = () => {
		// toggle the active boolean in the state
		this.setState(
			{
				active: !this.state.active,
			},
			// after state has been updated,
			() => {
				// set the class in state for the navbar accordingly
				this.state.active
					? this.setState({
						navBarActiveClass: 'is-active',
					})
					: this.setState({
						navBarActiveClass: '',
					});
			}
		);
	};

	toggleCommHamburger = () => {
		this.setState({ commHamburgerOpen: !this.state.commHamburgerOpen })
	}


	render() {
		return (
			<StyledHeader
				className="navbar is-transparent"
				role="navigation"
				aria-label="main-navigation"
				page={this.props.page}
			>
				<div className="nav-container">
					<div className="navbar-brand">
						<Link to="/" className="navbar-item" title="Logo">
							<img src={logo} alt="Kaldi" />
						</Link>
						{/* Hamburger menu */}
						<button
							className={`navbar-burger burger ${this.state.navBarActiveClass}`}
							data-target="navMenu"
							onClick={() => this.toggleHamburger()}
						>
							<span />
							<span />
							<span />
						</button>
						<div className="social-icons tablet">
							<SocialIcons />
						</div>
					</div>
					<nav
						id="navMenu"
						className={`navbar-menu ${this.state.navBarActiveClass}`}
						role="navigation"
					>
						<div className="navbar-start has-text-centered" />
						<ul className="navbar-end has-text-centered">
							<li className="navbar-item">
								<Link to="/sell-your-home">Sell Your Home</Link>
							</li>
							<li className="navbar-item">
								<Link to="/buy-a-home">Buy a Home</Link>
							</li>
							<li className="navbar-item">
								<Link to="/listings">Listings</Link>
							</li>
							<li className="navbar-item">
								<Link to="/blog">Blog</Link>
							</li>
							<li className="navbar-item">
								<Link to="/our-team">Team</Link>
							</li>
							<li className="navbar-item has-dropdown is-hoverable">
								<a className="navbar-link" onClick={this.toggleCommHamburger}>Communities {this.state.commHamburgerOpen ? "-" : "+"}</a>
								{this.state.commHamburgerOpen &&
									<ul className="navbar-dropdown">
										{this.props.communities.sort().map((community) => (
											<li key={community}><Link to={`/communities/${community.toLowerCase().replaceAll(' ', '-')}`}>{community}</Link></li>
										))}
									</ul>
								}
							</li>
							<li className="navbar-item">
								<Link to="/contact">Contact</Link>
							</li>
						</ul>
						<div className="social-icons mobile">
							<SocialIcons />
						</div>
					</nav>
				</div>
			</StyledHeader>
		);
	}
}

export default Header;
